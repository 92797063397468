<template>
  <div class="equity-container">
    <p class="card-title">我的会员</p>
    <div class="detail-container">
      <div class="container-title">
        <p>授权详情</p>
        <div class="member-tips" @click="handleBackMember">
          <el-icon name="arrow-left" /> 返回
        </div>
      </div>
      <div class="table-wrapper">
        <el-table ref="multipleTable" border :data="list" header-cell-class-name="table_header"
          :cell-style="TableRowStyle">
          <el-table-column label="会员名称" prop="goods_name" align="center" width="120">
            <template slot-scope="scope">
              {{ `${scope.row.goods_type === 1 ? '个人' : '企业'}${scope.row.goods_name}` }}
            </template>
          </el-table-column>
          <el-table-column label="会员有效期" align="center">
            <template slot-scope="{ row: { start_at, end_at } }">
              {{ start_at.replace(/-/g, '/') | parseDate('yyyy.MM.dd') }} /
              {{ end_at.replace(/-/g, '/') | parseDate('yyyy.MM.dd') }}
            </template>
          </el-table-column>
          <el-table-column label="可授权企业数" prop="cert_num" align="center" />
          <el-table-column label="剩余可授权企业数" align="center">
            <template slot-scope="{ row: { cert_num, contracts } }">
              {{ cert_num - contracts.length }}
            </template>
          </el-table-column>
          <el-table-column label="已授权企业" align="left" width="300" class-name="noPadding">
            <template slot-scope="{ row: { contracts } }">
              <p class="rowShow" v-for="(m, i) in contracts" :key="i">{{ m.name }} <span class="down-btn"
                  @click="downloadAgreement(m.contract_id)">下载协议</span></p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- pdf -->
    <PurchaseProtocol v-if="info && info.contract_id && info.contract_id !== ''" ref="PurchaseProtocol" />
  </div>
</template>

<script>
import Equity from '@/views/member/equity/mixins/equity'
import { fetchUserVipInfo } from '@/api/user'
import { ORDER_PAY_STATUS, VIP_TYPE } from '@/enum/common'
import PurchaseProtocol from '@/components/VfPdf/PurchaseProtocol'

export default {
  components: {
    PurchaseProtocol
  },
  data() {
    return {
      list: [],
      id: null,
      info: null,
      vipType: [...Object.values(VIP_TYPE)],
      orderPayStatus: [...Object.values(ORDER_PAY_STATUS)]
    }
  },
  created() {
    this.id = this.$route.params.id // 必要项
    this.getVipInfo()
  },
  mixins: [Equity],
  methods: {
    TableRowStyle({ rowIndex }) {
      let tableRowStyle = "";
      if (rowIndex % 2 !== 0) {
        tableRowStyle = tableRowStyle + "background-color: #FAFAFA;border-radius: 2px;";
      }
      return tableRowStyle;
    },
    downloadAgreement(contract_id) {
      console.log(contract_id)
      this.$nextTick(() => {
        this.$refs.PurchaseProtocol.initPDFPage(contract_id)
      })
    },
    /**
     * 获取vip信息
     * 2021-12-23 13:42:31
     * @author SnowRock
     */
    async getVipInfo() {
      try {
        const { data } = await fetchUserVipInfo({
          order_id: this.id
        })
        this.info = data
        this.list.push(data)
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 返回会员中心
     */
    handleBackMember() {
      // this.$router.push('/member/center/equity')
      this.$router.go(-1);
    },
    /**
     * 授权协议下载
     * 2021-12-22 13:44:50
     * @author SnowRock
     */
    handleDownload() {
      if (this.info.contract_id) {
        this.$nextTick(() => {
          this.$refs.PurchaseProtocol.initPDFPage(this.info.contract_id)
        })
      } else {
        const refNames = {
          2: 'AuthInfoForCompany',
          1: 'AuthInfoForPeople'
        }
        this.$refs[refNames[this.info.goods_type]].handleOpenDialog(this.info)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.equity-container {
  padding: 32px $padding-lr;
  color: #333333;

  .card-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }

  .detail-container {
    width: 100%;
    min-height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 40px;
    padding: 30px 50px;

    .container-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .member-tips {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    cursor: pointer;
  }

  .auth-tips {
    font-size: 14px;
    //color: #E52A0D;
    padding-left: 44px;

    .el-icon-warning {
      margin-right: 5px;
      color: #ff8e14;
    }
  }

  .equity-level-container {
    .equity-list {
      ul {
        padding-left: 38px;

        li {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 20px;

          .label-name {
            min-width: 84px;
            white-space: nowrap;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            margin-right: 20px;
          }

          .label-info {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);

            .list-firm {
              margin-bottom: 11px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .op-btn {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0061F3;
            margin-left: 12px;
            cursor: pointer;
          }

          &.qrcode {
            .label-info {
              width: 168px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.6);
              line-height: 20px;

              .qrcode-img {
                width: 120px;
                height: 120px;
                background: #d8d8d8;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

/deep/.el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border: none;
}

/deep/.el-table--group::after,
.el-table--border::after {
  width: 0;
}

/deep/.el-table--border .el-table__cell {
  border-right: 1px solid #F5F6FA;
}

/deep/.el-table td.el-table__cell {
  border-bottom: 1px solid #F5F6FA;
}

/deep/.el-table--group,
.el-table--border {
  border: 0;
}

/deep/.el-table {
  &::before {
    height: 0;
  }

  .noPadding {
    .cell {
      padding: 0;

      .rowShow {
        width: 100%;
        padding: 14px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F5F6FA;
        padding-left: 45px;

        &:last-child {
          border: none;
        }
      }
    }
  }

  .el-table__header {

    .el-table__cell,
    .is-leaf {
      border: none;

      &:first-child {
        border-radius: 6px 0 0 0;
      }

      &:nth-child(5) {
        border-radius: 0 6px 0px 0;
      }
    }

    .noPadding {
      padding-left: 45px;
    }
  }


  .table_header {
    background: #F5F6FA;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }

  .el-table__header tr,
  .el-table__header th {
    padding: 0;
    height: 42px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  .el-table__body tr,
  .el-table__body td {
    padding: 0;
    height: 48px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);

    &:first-child {
      border-left: 1px solid #F5F6FA;
    }
  }
}

.down-btn {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0061F3;
  cursor: pointer;
  margin-left: 12px;
}
</style>
